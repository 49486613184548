import { css } from "@emotion/react";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useState } from "react";
import VehicleMap from "./VehicleMap";
import useWebSocket from "./webSocketHelper";

const loader = css`
  top: 45%;
  left: 45%;
  width: 100%;
  height: 100vh;
`;

const App = () => {
  const [buses, setBuses] = useState();
  const [busData, setBusData] = useState();
  const [currentSaccoBuses, setCurrentSaccoBuses] = useState();
  const [currentBus, setCurrentBus] = useState();
  const [webSocketResponse, setWebSocketResponse] = useState([]);
  const [telematicsIds, setTelematicsIds] = useState([]);
  const [newVehicleLocation, setNewVehicleLocation] = useState([]);
  const [receivedLiveData, setReceivedLiveData] = useState(false);

  async function getData() {
    fetch("https://payd-api.basi-go.com/api/public/vehicles", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setBusData(json.results);
      });
  }
  useEffect(() => {
    if (busData === undefined) {
      getData();
    }
  }, []);
  function getSacco() {
    if (busData !== undefined) {
      const uniqueOperatorNames = Array.from(new Set(busData.map((item) => item.operator_name)));
      let options = uniqueOperatorNames.map((operatorName) => {
        return (
          <option key={operatorName} value={operatorName}>
            {operatorName}
          </option>
        );
      });
      return options;
    }
  }
  function getBus() {
    if (currentSaccoBuses !== undefined) {
      let options = currentSaccoBuses?.map((item) => {
        return (
          <option key={item.telematics_id} value={item?.registration_number}>
            {item?.registration_number}
          </option>
        );
      });
      return options;
    }
  }
  useEffect(() => {
    if (busData !== undefined) {
      const currentOperatorName = busData?.[0]?.operator_name;
      const currentBuses = busData?.filter((bus) => bus.operator_name === currentOperatorName);
      const telematics_id = currentBuses?.map((vehicle) => vehicle.telematics_id);
      setTelematicsIds(telematics_id);
      setCurrentSaccoBuses(currentBuses);
    }
  }, [busData]);

  const changeSaccoSelectOptionHandler = (event) => {
    const currentBuses = busData?.filter((bus) => bus.operator_name === event.target.value);
    const telematics_id = currentBuses?.map((vehicle) => vehicle.telematics_id);
    setTelematicsIds(telematics_id);
    setCurrentSaccoBuses(currentBuses);
  };

  const changeBusSelectOptionHandler = (event) => {
    const currentBus = currentSaccoBuses?.filter((bus) => bus.registration_number === event.target.value);
    setCurrentBus(currentBus);
  };


  const liveData = useWebSocket(telematicsIds);

  useEffect(() => {
    if (liveData) {
      setReceivedLiveData(true);
      setNewVehicleLocation((prevLocations) => {
        const prevIndex = prevLocations.findIndex((data) => data?.telematics_id === liveData?.telematics_id);

        if (prevIndex !== -1) {
          // If the telematics object exists in the previous locations, update it
          return prevLocations.map((prevLocation, index) => {
            if (index === prevIndex) {
              // Update only the telematics object, retain the rest
              return { ...prevLocation, ...liveData };
            } else {
              return prevLocation;
            }
          });
        } else {
          // If the telematics object doesn't exist in the previous locations, add it
          return [...prevLocations, { ...liveData }];
        }
      });
    }
  }, [liveData]);

  const paramsVehicleLocation = () => {
    if (currentBus !== undefined && currentBus !== null) {
      return newVehicleLocation?.filter((bus) => {
        return (
          !Number.isNaN(bus.latitude) &&
          !Number.isNaN(bus.longitude) &&
          bus.telematics_id === currentBus[0]?.telematics_id
        );
      });
    }
    return newVehicleLocation?.filter((bus) => !Number.isNaN(bus.latitude) && !Number.isNaN(bus.longitude));
  };
  const filteredVehicleLocation = paramsVehicleLocation();
  const mergedData = mergeFleetAndTelematicsData(busData, filteredVehicleLocation);

  // useEffect(() => {
  //   const filteredVehicleLocation = paramsVehicleLocation();

  //   const mergedData = mergeFleetAndTelematicsData(busData, filteredVehicleLocation);
  //   console.log(telematicsIds);
  //   console.log(mergedData);
  //   if (telematicsIds && mergedData !== undefined) {
  //     console.log(mergedData);
  //     const data = mergedData?.filter((item) => telematicsIds?.includes(item.telematics_id));
  //     console.log(data);
  //     console.log("telematicsIds", telematicsIds);
  //     setBuses(data);
  //   }
  // }, [telematicsIds]);

  return (
    <div>
      <select
        onChange={changeSaccoSelectOptionHandler}
        style={{
          position: "fixed",
          border: "solid #ededed 1px",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
          color: "#333333",
          fontWeight: 600,
          borderRadius: 6,
          top: 10,
          width: "100%",
          zIndex: 1000,
          height: "40px",
          left: "2%",
        }}
      >
        {getSacco()}
      </select>

      {/* <select
        onChange={changeBusSelectOptionHandler}
        style={{
          position: "fixed",
          border: "solid #ededed 1px",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
          color: "#333333",
          fontWeight: 600,
          borderRadius: 6,
          top: 10,
          width: "47%",
          zIndex: 1000,
          height: "40px",
          right: "2%",
        }}
      >
        {getBus()}
      </select> */}
      <VehicleMap newVehicleLocation={mergedData} telematicsIds={telematicsIds} />
    </div>
  );
};

export default App;

const mergeFleetAndTelematicsData = (fleet, telematicsState) => {
  const telematicsMap = new Map();
  if (telematicsState.length > 0) {
    telematicsState?.forEach((item) => {
      if (!Number.isNaN(item.latitude) && !Number.isNaN(item.longitude)) {
        telematicsMap?.set(item.telematics_id, item);
      }
    });
    const mergedData = [];

    fleet?.forEach((fleetItem) => {
      if (telematicsMap?.has(fleetItem.telematics_id)) {
        const matchingTelematics = telematicsMap?.get(fleetItem.telematics_id);
        const mergedItem = { ...fleetItem, ...matchingTelematics };
        mergedData.push(mergedItem);
      }
    });
    return mergedData;
  }
};
