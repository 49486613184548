import React, { useEffect, useRef, useState } from "react";
import { Map, Marker, Popup } from "react-map-gl";

const VehicleMap = ({ newVehicleLocation, telematicsIds }) => {
  const mapRef = useRef();
  const [currentLocation, setCurrentLocation] = useState([]);

  useEffect(() => {
    if (telematicsIds && newVehicleLocation !== undefined) {
      const data = newVehicleLocation?.filter((item) => telematicsIds?.includes(item.telematics_id));
      setCurrentLocation(data);
    }
  }, [newVehicleLocation, telematicsIds]);
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <Map
        ref={mapRef}
        initialViewState={{
          latitude: -1.2832533,
          longitude: 36.8172449,
          zoom: 13,
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/dev-basi-go/cm0c3kgtl00r101phc0jo1fib"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        {currentLocation?.length > 0 ? (
          currentLocation?.map((vehicle) => (
            <Marker key={vehicle?.telematics_id} latitude={vehicle?.latitude} longitude={vehicle?.longitude}>
              <img src="map_marker.png" alt="Bus" />
              <Popup
                anchor="bottom"
                longitude={vehicle?.longitude}
                latitude={vehicle?.latitude}
                closeOnClick={false}
                closeButton={false}
                static={true}
                className="vehicle-tooltip"
              >
                <div className="vehicle-tooltip_popup">
                  <p>{vehicle?.registration_number}</p>
                </div>
              </Popup>
            </Marker>
          ))
        ) : (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "99",
              backgroundColor: "rgba(211, 211, 211, 0.5)",
            }}
          >
            <div className="loader">Loading...</div>
          </div>
        )}
      </Map>
    </div>
  );
};

export default VehicleMap;
