import { useEffect, useState } from "react";

const useWebSocket = (telematicsIds) => {
  const [webSocketResponse, setWebSocketResponse] = useState();

  useEffect(() => {
    // Initialize an array to hold all WebSocket connections
    if (telematicsIds !== undefined) {
      const sockets = [];

      // Check if only one telematics ID is provided
      const isSingleTelematicsId = telematicsIds.length === 1;

      // Function to create WebSocket connection
      const createSocket = (id) => {
        const websocketUrl = `${process.env.REACT_APP_DROGON_SOCKET}/${id}`;
        const socket = new WebSocket(websocketUrl);

        socket.onopen = () => {
          // console.log(`WebSocket connection established for telematics ID: ${id}`);
        };

        socket.onmessage = (event) => {
          // console.log(`Received message for telematics ID ${id}:`, event.data);
          if (event.data) {
            const validJsonString = event.data.slice(1, -1).replace(/\\"/g, '"');
            try {
              const parsedResponseData = JSON.parse(validJsonString);
              if (parsedResponseData) {
                setWebSocketResponse(parsedResponseData);
              }
            } catch (error) {
              // console.log("Error parsing Json:", error);
            }
          } else {
            // console.error("No Data received in the event.");
          }
        };

        socket.onerror = (error) => {
          // console.error(`WebSocket error for vehicle ID: ${id}`, error);
        };

        socket.onclose = () => {
          // console.log(`WebSocket closed for vehicle ID: ${id}`);
        };

        // Add the socket to the array
        sockets.push(socket);
      };

      // Close existing connections before creating new ones
      sockets.forEach((socket) => {
        socket.close();
      });

      telematicsIds?.forEach((id) => {
        createSocket(id);
      });

      // Cleanup function to close all WebSocket connections
      return () => {
        sockets.forEach((socket) => {
          socket.close();
        });
      };
    }
  }, [telematicsIds]);

  return webSocketResponse;
};

export default useWebSocket;
